.pg-viewer-wrapper {
    overflow: hidden;
    width: 300px;
    height: 250px;
    background-color: #242424;
    border-radius: 10px;
}

.pg-viewer-wrapper video {
    width: 100%;
    height: 100%;
}

.pg-viewer-wrapper img {
    width: 100%;
    height: 100%;
}